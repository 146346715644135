.wrapper {
  margin-top: 56px;
}

.thActionRow {
  width: 50px;

  //> div {
  //  display: flex;
  //}

  :global {
    .action {
      cursor: pointer;

      &:hover {
        path {
          opacity: 1;
        }
      }

      &.save {
        height: 16px;
        width: 15px;
      }

      &.cancel {
        height: 14px;
        width: 13px;
      }
    }
  }
}

.thName {
  width: 35%;
}

.thTitle {
  width: 25%;
}

.thEmail {
  width: 25%;
}

.thRate {
  width: 10%;
}
