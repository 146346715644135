@import 'src/styles/variables';
@import 'src/styles/mixins';
@import 'src/styles/helpers';

.widgets {
  grid-template-rows: 1fr 2px 1fr;

  .widgetTop {
    flex-direction: column;
    display: flex !important;
    align-items: center;

    .week {
      font-size: 16px;
      font-weight: $mainFontWeight;
      width: 85%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .arrow {
        @extend .cursor-pointer;

        &.arrowLeft {
          transform: rotate(180deg);
        }
      }
    }

    .info {
      display: grid;
      margin-bottom: 10px;
      margin-top: 6px;

      .infoBlock {
        display: flex;
        flex-direction: column;
        align-items: center;

        .value {
          font-size: 26px;
          line-height: 36px;
          font-weight: $mainFontWeight;
        }

        .text {
          font-size: 13px;
          line-height: 13px;
          font-weight: $mainFontWeight;
        }
      }
    }

    .viewModal {
      font-size: 13px;
      font-weight: $mainFontWeight;
      text-decoration-line: underline;
      cursor: pointer;
    }
  }

  .hr {
    height: 1px;
    background-color: #c6c6c6;
    border: none;
    margin: 0;
  }

  .widgetBottom {
    flex-direction: column;
    display: flex !important;
    align-items: center;
    margin-top: 6px;

    .header {
      font-size: 16px;
      font-weight: $mainFontWeight;
    }

    .info {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 10px 15px;
      margin-bottom: 10px;

      .infoBlock {
        display: flex;
        flex-direction: column;
        align-items: center;

        .value {
          font-size: 26px;
          line-height: 36px;
          font-weight: $mainFontWeight;
        }

        .text {
          font-size: 13px;
          line-height: 13px;
          font-weight: $mainFontWeight;
        }
      }
    }

    .viewModal {
      font-size: 13px;
      font-weight: $mainFontWeight;
      text-decoration-line: underline;
      cursor: pointer;
    }
  }
}

/** modal */
.modalWrapper {
  width: 834px;

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .header {
      flex-direction: column;
      width: 170px;
      display: flex;
      align-items: center;
      justify-self: center;
      margin-bottom: 20px;
    }

    .week {
      font-size: 16px;
      font-weight: $mainFontWeight;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .arrow {
        @extend .cursor-pointer;

        &.arrowLeft {
          transform: rotate(180deg);
        }
      }
    }

    .button {
      width: 250px;
      font-size: 14px;
      font-weight: $mainFontWeight;
      height: 30px;
      margin-top: 20px;
    }

    .ellipsable {
      width: 224px;

      @include nowrap-ellipsis();
    }
  }
}
