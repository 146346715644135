@import 'src/styles/variables';
@import 'src/styles/mixins';
@import 'src/styles/helpers';
@import 'src/styles/inputs/checkbox';

.statusColumn {
  width: 170px;
  text-align: center;
}

.taskName {
  @extend .cursor-pointer;
}

.ellipsable {
  width: 200px;

  @include nowrap-ellipsis();
}

.ellipsableAssignee {
  width: 100px;

  @include nowrap-ellipsis();
}

.checkbox {
  :global {
    .UiInput.checkbox {
      &:checked {
        + label::before {
          background-size: 10px !important;
        }
      }
    }
  }
}

.top {
  display: flex;
  flex-direction: row;
  gap: 23px;
  margin-bottom: 52px;

  .taskDropdown {
    width: 380px !important;
  }

  .createNewTask {
    width: 250px;
  }
}

.head {
  font-size: 14px;
  font-weight: $mainFontWeight;
}

.doneColumn {
  :global {
    .UiInput-wrapper {
      width: initial !important;

      &.checkboxWrapper {
        @include customCheckboxSize(16px);
        @include customCheckboxBorderRadius(50%);
      }
    }
  }
}
