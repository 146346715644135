@import 'src/styles/variables';
@import 'src/styles/mixins';

.messagesBlock {
  margin-bottom: 14px;

  $collapseIconWidth: 13px;
  $gap: 14px;

  .top {
    display: grid;
    grid-template-columns: $collapseIconWidth 1fr;
    gap: $gap;
    align-items: center;
    margin-bottom: 10px;

    .collapseIcon {
      width: 13px;
      cursor: pointer;
    }

    .collapseIconOpen {
      transform: rotate(180deg);
    }

    .header {
      font-size: 24px;
      font-weight: $mainFontWeight;
    }
  }

  .center {
    display: grid;
    grid-template-columns: $collapseIconWidth 1fr;
    gap: $gap;
    align-items: center;

    .content {
      display: grid;
      gap: 13px;
      overflow: auto;
      padding-right: 10px;

      .item {
        height: 50px;
        background-color: #fff;
        display: grid;
        grid-template-columns: 30px 1fr 105px;
        gap: 10px;
        justify-content: left;
        align-items: center;
        padding-right: 11px;
        padding-left: 11px;
        font-size: 14px;
        font-weight: $mainFontWeight;

        @include border-radius($borderRadius);

        .counter {
          width: 29.1px;
          height: 29.1px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: $mainColorOpposite;
          font-weight: 700;
          background-color: $mainColor; // default color

          @include border-radius(50%);
        }

        .projectName {
          @include nowrap-ellipsis;
        }
      }

      .noComments {
        font-size: 16px;
        font-weight: $mainFontWeight;
      }
    }
  }
}
