@import 'src/styles/variables';
@import 'src/styles/mixins';

.r-tags-input-wrapper {
  display: flex;
  flex-wrap: wrap;
  //height: $inputHeight;
  margin-bottom: 24px;
  flex-direction: row !important;

  $innerMargin: 3px;

  .r-tags-input {
    flex: 1 1 auto;
    flex-basis: 100%;
    width: 1%;
    min-width: 0;
    flex-wrap: wrap;
    height: auto;
    display: inline-flex;
    background: $mainColorOpposite;
    @include border-radius($borderRadius);
    border: 1px solid $borderColor;
    padding: $innerMargin;
    gap: 5px;

    &.error {
      border: 1px solid red;
    }

    &.noElements {
      padding: $innerMargin;
    }

    input {
      width: auto;
      flex: 1 1 auto;
      border: none !important;
      margin-bottom: 0;
      height: $inputHeight - 7px;

      &:focus,
      &:focus-visible {
        border: none !important;
        outline: none !important;
      }
    }

    .element {
      padding: 4px 12px;
      background: rgba(98, 94, 233, 0.08);
      @include border-radius(5000px);
      height: 27px;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: $mainColor;

      &.invalid {
        background: #eb5757;
        color: #fff;

        svg {
          fill: #fff;
        }
      }

      button {
        background: none !important;
        border: none;
        padding: 0 !important;
        font-family: inherit;
        cursor: pointer;
        box-shadow: none !important;
        max-width: fit-content;
        width: fit-content;

        svg {
          margin-left: 11px;
          width: 10px;
          height: 10px;
          fill: #50545e;
        }
      }
    }
  }

  .validation.error {
    margin-top: 12px;
    display: flex;

    .validation-result {
      color: #eb5757;
      font-weight: $mainFontWeight;
      font-size: 12px;
      line-height: 12px;
    }

    svg {
      path {
        fill: #eb5757;
      }
    }
  }
}
