@import 'variables';
@import 'src/styles/mixins';
@import 'src/styles/variables';

:root {
  --dropdownBorderColor: #{$borderColor};
  --dropdownBackgroundColor: #{$mainColorOpposite};
}

$searchableHeight: 28px;

.react-select {
  &__control {
    border: none !important;
    box-shadow: none !important;
    min-height: $inputHeight !important;
    height: $inputHeight !important;
    background-color: initial !important;
    cursor: pointer !important;

    &--is-focused {
      height: $inputHeight !important;
      border: none !important;
    }
  }

  &__menu-portal > &__menu {
    margin-top: -4px;
  }

  //&__menu-list {
  //  max-height: 115px !important; // UiDropdown maxHeight
  //}

  &__single-value {
    line-height: 18px;
  }

  &__menu-list {
    @include customScrollbar;
  }

  &__value-container {
    margin-top: -4px;
    height: 35px;
  }

  &__indicator-separator {
    display: none;
  }

  &__option {
    @include nowrap-ellipsis();

    &--is-selected {
      background-color: $mainColor !important;
      color: $mainColorOpposite !important;
    }

    &--is-focused {
      background-color: #e1f5f4 !important;
      color: $borderColor !important;
      cursor: pointer !important;
    }
  }

  //&.react-select-searchable {
  //  &__control {
  //    min-height: $searchableHeight !important;
  //    height: $searchableHeight !important;
  //
  //    &--is-focused {
  //      height: $searchableHeight !important;
  //    }
  //  }
  //}
}

.UiInput-wrapper .UiInput.dropdown {
  height: $inputHeight !important;

  //&.searchable {
  //  height: $searchableHeight !important;
  //}

  //width: 100% !important;
  padding: 0 !important;
  background-color: var(--dropdownBackgroundColor);
  border-color: var(--dropdownBorderColor);

  &.small {
    $height: 24px;

    border-radius: $height !important;
    height: $height !important;

    .react-select {
      &__control {
        height: $height !important;
      }

      &__indicators {
        height: $height !important;
      }

      &__value-container {
        margin-top: -12px;
      }
    }
  }

  .chevron-down {
    display: inline-block;
    fill: currentColor;
    line-height: 1;
    stroke: currentColor;
    stroke-width: 0;
    margin-left: auto;
    flex-shrink: 0;
  }
}

.dropdownToggler {
  button {
    width: 100%;
    justify-content: left;
    border: none !important;

    span {
      @include nowrap-ellipsis();
    }
  }
}
