@import 'src/styles/variables';
@import 'src/styles/mixins';
@import 'src/styles/helpers';

.widget {
  $imageHeight: 158.84px;
  $paddingTop: 20px;
  padding: $paddingTop 22px;
  grid-template-rows: $imageHeight + $paddingTop 1fr;

  .imageWrapper {
    background-color: #eceef0;
    height: $imageHeight;
    width: 100%;
    align-self: start;

    @include border-radius($borderRadius);
    @extend .cursor-pointer;

    .emptyImage {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    :global {
      .fileUploader {
        height: 100%;
      }

      img {
        width: 100%;
        height: 100%;
        display: block;
        flex-shrink: 0;
        object-fit: cover;
        @include border-radius($borderRadius);
      }
    }
  }

  .phaseDropDown {
    width: 100%;
    position: absolute;
    top: 85px;
    display: none;

    :global {
      .react-select__menu-list {
        max-height: 93px !important;
      }
    }
  }

  .phaseDropDownShow {
    display: initial;
  }

  .content {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: baseline;
    gap: 10px;
    overflow: hidden;
    align-self: flex-start;
    position: relative;

    .projectNumber {
      @include border-radius($borderRadius);

      color: $mainColorOpposite;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      font-weight: $mainFontWeight;
      padding: 2px 5px;
      line-height: 12px;
    }

    .header {
      font-size: 18px;
      font-weight: $mainFontWeight;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 100%;
    }

    .phase {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 100%;

      @extend .cursor-pointer;

      :global {
        svg {
          margin-left: 8px;
        }
      }
    }

    .text {
      font-weight: 400;
    }

    .editBtn {
      font-size: 14px;
      font-weight: $mainFontWeight;
      display: flex;
      align-items: center;
      text-decoration: none;
      color: $mainColor;

      @extend .cursor-pointer;

      :global {
        svg {
          margin-right: 10px;
          margin-top: -3px;
        }
      }
    }
  }
}
