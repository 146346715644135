@import 'src/styles/variables';

.popover-arrow {
  display: none !important;
}

.deleteConfirmPopover {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 210px;
  gap: 6px;

  span {
    margin-left: 18px;
    font-size: 12px;
    font-weight: $mainFontWeight;
  }

  button {
    font-size: 12px;
    font-weight: $mainFontWeight;
    width: 85px;
    height: 27px;
  }
}

.popoverWrapper {
  display: inline-block;
}
