@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
  height: 12.33px;
  width: 100%;
  background-color: #edeff1;
  @include border-radius($borderRadius);
  overflow: hidden;

  :global {
    div {
      height: 12.33px;
      background-color: $borderColor;
    }
  }
}
