@import 'src/styles/mixins';
@import 'src/styles/inputs/checkbox';

.modal {
  width: 805px !important;

  .body {
    padding: 23px 23px 0;
  }

  .header {
    text-align: left;
  }
}

.wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;

  :global {
    .UiInput-wrapper {
      width: initial !important;
      margin-bottom: 18px !important;

      &.checkboxWrapper {
        @include customCheckboxSize(16px);
      }
    }

    .UiInput.dropdown {
      .react-select {
        &__option {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }

  .wysiwyg {
    height: 420px !important;
  }

  .bottomBlock {
    display: grid;
    padding: 0 10px;
    align-items: end;
    height: 58px;
    margin-top: 22px;
  }
}
