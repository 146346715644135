@import 'mixins';
@import 'src/styles/variables';

.button {
  height: $inputHeight;

  @include border-radius($borderRadius);

  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  @mixin disabled($color) {
    &[disabled] {
      background-color: lighten($color, 20%) !important;
    }
  }

  &.dark {
    background-color: $borderColor;
    color: $mainColorOpposite;
    border: none;

    @include disabled($borderColor);
  }

  &.light {
    background-color: initial;
    color: $borderColor;
    border: 1px solid $borderColor;
  }

  &.green {
    background-color: $green;
    color: #fff;
    border: none;

    //@include disabled($green);
  }

  &.red {
    background-color: $red;
    color: #fff;
    border: none;

    //@include disabled($red);
  }

  &.link {
    background-color: initial;
    color: $mainColor;
    text-decoration: underline;
    border: none;
  }
}
