@import 'src/styles/variables';
@import 'src/styles/mixins';
@import 'src/styles/helpers';
@import 'src/styles/inputs/checkbox';

:global {
  .loading {
    pointer-events: none;
  }
}

.wrapper {
  font-size: 14px;
  font-weight: $mainFontWeight;

  .topWrapper {
    display: flex;
    gap: 14px;
    justify-content: end;

    .backBtn {
      padding: 10px 41px;
    }

    .saveBtn {
      padding: 10px 94px;
    }
  }

  .inputs {
    display: grid;
    gap: 12px;
    grid-template-columns: 1fr 1fr 1fr;
    margin-top: 18px;

    :global {
      .UiInput-wrapper {
        .prefix {
          top: 39px;
        }

        .UiInput {
          height: 50px;
          font-size: 24px;
          font-weight: $mainFontWeight;
        }
      }
    }
  }

  .centerWrapper {
    display: grid;
    grid-template-columns: 1fr 381px;
    gap: 12px;

    h5 {
      font-size: 14px;
      font-weight: $mainFontWeight;
    }
  }

  .phase {
    .phaseItem {
      display: grid;
      grid-template-columns: 35px calc(100% - 35px);
      margin-bottom: 24px;
      align-items: center;

      :global {
        .UiInput-wrapper {
          margin-bottom: 0;
        }
      }
    }

    .phaseAddNew {
      @extend .cursor-pointer;
    }
  }

  .addNew {
    margin-top: 14px;

    @extend .cursor-pointer;

    display: inline-table;

    :global {
      svg {
        margin-right: 15px;
      }
    }
  }
}

.phaseItemEditable {
  border: 1px solid $borderColor;

  @include border-radius($borderRadius);

  padding: 11px 20px;
  height: 200px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
  margin-bottom: 11px;

  :global {
    .UiInput-wrapper {
      margin-bottom: 0;

      .UiInput {
        font-weight: $mainFontWeight;
      }
    }
  }

  .phaseFooter {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    grid-column: 2 / 4;

    :global {
      .button:first-child {
        display: flex;
        width: 50%;
        justify-self: end;
      }
    }
  }
}

.phaseItemCard {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-bottom: 11px;
  border: 1px solid $borderColor;

  @include border-radius($borderRadius);

  padding: 38px 29px 39px 20px;

  .phaseCardNames {
    font-size: 24px;
    line-height: 24px;
    margin-bottom: 6px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .phaseCardInfoIcons {
    display: flex;
    flex-direction: column;
    align-items: center;

    :global {
      svg.action {
        cursor: pointer;
      }
    }
  }

  .phaseCardInfo {
    color: $borderColor;
    font-size: 12px;
    font-weight: $mainFontWeight;

    &:nth-of-type(1) {
      justify-self: left;
      text-align: left;
      overflow: hidden;
      max-width: 100%;
    }

    &:nth-of-type(2) {
      justify-self: center;
    }

    &:nth-of-type(3) {
      justify-self: end;
      text-align: right;
    }
  }
}

.leaveModal {
  width: 451px;

  .leaveModalButtons {
    display: flex;
    gap: 14px;
  }

  :global {
    .modalHeader,
    .modalBody {
      padding-left: 28px;
      padding-right: 28px;
    }

    .bodyContent {
      padding-right: 0;
    }

    .button {
      flex: 1;
      height: 37px;

      &.red {
        flex: 2;
        background-color: #f10000;
      }
    }
  }
}
