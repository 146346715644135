@import 'variables';
@import 'mixins';

$bgColor: #f9f9f9;

:root {
  --fc-border-color: #{$mainColor};
  --fc-button-active-bg-color: #{$mainColor};
  --fc-button-border-color: #{$mainColor};
  --fc-button-hover-border-color: #{$mainColor};
  --fc-event-bg-color: #{$bgColor};
}

.calendar-event {
  cursor: pointer;
  border: 1px solid $borderColor;
  border-radius: $borderRadius;
  font-size: 10px !important;
  font-weight: 500 !important;
  padding: 3px 6px;
  background-color: $bgColor;
  min-height: 30px;

  .month-item {
    display: grid;
    grid-template-columns: 11px 1fr 10px;
    justify-content: center;
    align-items: center;
    color: $mainColor;
    width: 100%;
    gap: 4px;

    &.rows-4 {
      grid-template-columns: 11px 1fr 43px 10px;
    }

    .color {
      width: 11px;
      height: 11px;
      background-color: $borderColor;
      border-radius: 50%;
    }

    .name {
      @include nowrap-ellipsis;
    }

    .date {
    }

    svg {
      justify-self: end;
    }
  }

  .week-item {
    color: $mainColor;
    width: 100%;
    gap: 2px 4px;
    display: grid;
    grid-template-columns: 8px auto 9px;
    align-items: center;

    .color {
      width: 8px;
      height: 8px;
      background-color: $borderColor;
      border-radius: 50%;
    }

    .date,
    .name {
      line-height: 100%;
    }

    .name {
      grid-column: 1 / 4;
      @include nowrap-ellipsis;
    }
  }
}

// rewrite

.fc-more-popover > .fc-popover-body {
  max-width: 220px;
}

// month view
.fc-dayGridMonth-view {
  .calendar-more-link {
    border: 1px solid $borderColor;
    border-radius: $borderRadius !important;
    font-size: 10px !important;
    font-weight: 500 !important;
    padding: 5.5px 6px 5.5px 15px !important;
    width: 100%;
    background-color: $bgColor;
  }
}

.fc-timeGridWeek-view {
  .calendar-more-link {
    border: 1px solid $borderColor;
    border-radius: $borderRadius !important;
    background-color: $bgColor;
    text-decoration: none !important;
    font-size: 10px !important;
    font-weight: 500 !important;
    padding: 3.5px 3px 3.5px 5px !important;
  }
}

.fc-daygrid-event-harness {
  margin-bottom: 4px;
}

.fc-timegrid-event-harness-inset .fc-timegrid-event,
.fc-timegrid-event.fc-event-mirror,
.fc-timegrid-more-link {
  box-shadow: none !important;
}

// month, week
.fc-button-group {
  .fc-button {
    border-radius: $borderRadius;
    font-size: 14px;
    font-weight: $mainFontWeight;
    padding: 6px 16px;
    outline: none !important;
    box-shadow: none !important;
  }

  .fc-button:not(.fc-button-active) {
    background-color: #eceef0 !important;
    color: $mainColor !important;
  }
}

// end month, week

// next prev
.fc-backButton-button,
.fc-nextButton-button {
  width: 40px;
  height: 40px;
  border-radius: 50% !important;
  background-color: $mainColor !important;
  outline: none !important;
  box-shadow: none !important;
  background-position: center;
}

.fc-backButton-button {
  margin-right: 27px !important;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='41' height='41' viewBox='0 0 41 41' fill='none'%3E%3Cpath d='M21.9601 11.6304L21.9901 11.6604C22.5401 12.2204 22.5301 13.1104 21.9901 13.6604L16.4401 19.1904H29.5502C30.3302 19.1904 30.9701 19.8304 30.9701 20.6104C30.9701 21.3904 30.3302 22.0304 29.5502 22.0304H16.4401L21.9901 27.5804C22.5401 28.1304 22.5401 29.0304 21.9901 29.5904L21.9701 29.6104C21.4201 30.1604 20.5201 30.1604 19.9601 29.6104L11.3401 20.9904C11.1301 20.7804 11.1301 20.4404 11.3401 20.2304L19.9501 11.6204C20.5101 11.0604 21.4101 11.0704 21.9701 11.6204L21.9601 11.6304Z' fill='white'/%3E%3C/svg%3E");
}

.fc-nextButton-button {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='41' height='41' viewBox='0 0 41 41' fill='none'%3E%3Cpath d='M19.6204 29.6005L19.5903 29.5705C19.0403 29.0105 19.0503 28.1205 19.5903 27.5705L25.1404 22.0405H12.0304C11.2504 22.0405 10.6104 21.4005 10.6104 20.6205C10.6104 19.8405 11.2504 19.2005 12.0304 19.2005H25.1404L19.5903 13.6505C19.0403 13.1005 19.0403 12.2005 19.5903 11.6405L19.6104 11.6205C20.1604 11.0705 21.0604 11.0705 21.6204 11.6205L29.6104 19.6105C30.1604 20.1605 30.1604 21.0605 29.6104 21.6205L21.6304 29.6005C21.0704 30.1605 20.1704 30.1505 19.6104 29.6005H19.6204Z' fill='white'/%3E%3C/svg%3E");
}

// end next prev

.fc-daygrid-day-top,
.fc-scrollgrid-sync-inner {
  a {
    color: $mainColor;
    text-decoration: none;
    font-weight: 700;
    font-size: 14px;
  }
}

.fc .fc-daygrid-day-frame {
  min-height: 120px; // https://github.com/fullcalendar/fullcalendar/issues/5762
}

.fc-createNewButton-button {
  background-color: $mainColorOpposite !important;
  color: $mainColor !important;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  display: inline-flex !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  justify-content: center;
  align-items: center;
  height: 14px;

  &:before {
    width: 26px;
    height: 26px;
    margin-right: 8px;
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='27' height='28' viewBox='0 0 27 28' fill='none'%3E%3Cpath d='M12.5202 20.2597H14.7802V15.0396H20.0302V12.7796H14.7802V7.30965H12.5202V12.7796H7.08017V15.0396H12.5202V20.2597ZM13.5702 27.1396C11.7102 27.1396 9.97019 26.7896 8.35019 26.0996C6.73019 25.4096 5.31019 24.4496 4.10019 23.2396C2.89019 22.0296 1.93017 20.6096 1.24017 18.9896C0.550173 17.3696 0.200195 15.6296 0.200195 13.7696C0.200195 11.9096 0.550173 10.1596 1.24017 8.53965C1.93017 6.91965 2.89019 5.50965 4.10019 4.29965C5.31019 3.09965 6.73019 2.13965 8.35019 1.43965C9.97019 0.739648 11.7102 0.389648 13.5802 0.389648C15.4502 0.389648 17.1902 0.739648 18.8102 1.43965C20.4302 2.13965 21.8502 3.08965 23.0502 4.28965C24.2502 5.48965 25.2102 6.90965 25.9102 8.52965C26.6102 10.1496 26.9602 11.8996 26.9602 13.7696C26.9602 15.6396 26.6102 17.3796 25.9102 18.9996C25.2102 20.6196 24.2502 22.0296 23.0502 23.2396C21.8502 24.4496 20.4302 25.3996 18.8102 26.0996C17.1902 26.7996 15.4502 27.1497 13.5802 27.1497L13.5702 27.1396ZM13.5702 24.1796C16.4502 24.1796 18.9002 23.1697 20.9302 21.1497C22.9502 19.1297 23.9602 16.6697 23.9602 13.7597C23.9602 10.8497 22.9502 8.42965 20.9302 6.39965C18.9102 4.37965 16.4502 3.36965 13.5402 3.36965C10.6302 3.36965 8.21018 4.37965 6.18018 6.39965C4.16018 8.41965 3.15018 10.8797 3.15018 13.7896C3.15018 16.6996 4.16018 19.1196 6.18018 21.1497C8.20018 23.1697 10.6602 24.1796 13.5702 24.1796Z' fill='%233F5B64'/%3E%3C/svg%3E");
  }
}

// layout
.fc-daygrid-body,
.fc-scrollgrid-sync-table,
.fc-col-header {
  width: 100% !important;
}
// end layout
