@import 'src/styles/variables';

.content {
  width: 430px;
  height: 360px;

  :global {
    h2 {
      font-size: 22px;
      font-weight: $mainFontWeight;
      margin-bottom: 0;
    }

    p {
      font-size: 17px;
      font-weight: $mainFontWeight;
      margin-bottom: 0;
      line-height: 18px;
      width: 315px;
    }

    button {
      margin-top: 12px;
      padding: 0 33px;

      svg {
        width: 20px;
        margin-left: 10px;
      }
    }
  }

  .bodyContent {
    justify-content: center;
    height: 268px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 19px;
  }
}
