@import 'src/styles/variables';

$paddingX: 42px;
$paddingY: 26px;

.modal {
  display: block;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1051;
  outline: 0;
  overflow-x: hidden;
  overflow-y: auto;
  opacity: 1;
  backdrop-filter: blur(5px);
}

.dialog {
  margin: 25px auto;
  transition: transform 0.3s ease-out;
  min-height: calc(100% - 4rem);
  transform: none !important;
  align-items: center;
  display: flex;
  width: max-content;

  .arrow {
    position: relative;
    cursor: pointer;
    color: #fff;
    font-size: 12px;
    font-weight: $mainFontWeight;

    :global {
      svg {
        margin-bottom: 20px;
      }
    }
  }

  .arrowLeft {
    left: -30px;
  }

  .arrowRight {
    left: 30px;

    :global {
      svg {
        transform: rotate(180deg);
      }
    }
  }

  .points {
    position: absolute;
    display: flex;
    justify-content: center;
    width: 100%;
    gap: 6px;
    margin-top: 15px;

    .point {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      border: 1px solid #fff;
      cursor: pointer;
    }

    .pointActive {
      background-color: #fff;
    }
  }
}

.content {
  position: relative;
  background-color: $mainColorOpposite;
  border: 1px solid rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  outline: 0;
  border-radius: 12px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.06);
  width: 100%;
  text-align: center;
}

.backdrop {
  opacity: 0.5;
  z-index: 1050;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #000;
  display: block;
}

.closeButton {
  padding: 0;
  cursor: pointer;
  border: 0;
  position: absolute;
  right: 10px;
  top: 9px;
  background: none;
  z-index: 999999;
}

.header {
  font-size: 24px;
  margin: 0;
  font-weight: $mainFontWeight;
}

.modalHeader {
  padding: $paddingY $paddingX 0 $paddingX;
}

.modalBody {
  $forScroll: 15px;

  overflow-x: hidden;
  overflow-y: auto;
  display: grid;
  padding: $paddingY calc(#{$paddingX} - #{$forScroll}) $paddingY $paddingX;

  .bodyContent {
    padding-right: $forScroll;
  }
}

.modalFooter {
  padding: 0 $paddingY $paddingX $paddingY;
  display: grid;
}
