@import 'src/styles/variables';
@import 'src/styles/mixins';
@import 'src/styles/helpers';

:root {
  --checkboxBorderColor: #{$borderColor};
  --checkboxBackgroundColor: #{$mainColorOpposite};
  --checkboxCheckedBackgroundColor: #{$borderColor};
  --checkboxCheckedBorderColor: #{$borderColor};
}

/** outer usage for extending */
@mixin customCheckboxSize($size) {
  .UiInput.checkbox {
    height: $size !important;

    + label {
      &::before {
        width: $size;
        height: $size;
      }
    }
  }
}

@mixin customCheckboxBorderRadius($radius) {
  .UiInput.checkbox {
    + label {
      &::before {
        @include border-radius($radius);
      }
    }
  }
}

@mixin customCheckboxBorderColor($color) {
  .UiInput.checkbox {
    + label {
      &::before {
        border: 1px solid $color;
      }
    }
  }
}
/** end outer usage for extending */

.checkboxWrapper {
  .UiInput.checkbox {
    position: absolute;
    z-index: -1;
    opacity: 0;

    + label {
      display: inline-flex;
      align-items: center;
      user-select: none;
      margin-bottom: 0;
      @extend .cursor-pointer;

      span {
        margin-left: 10px;
      }

      &::before {
        content: '';
        display: inline-block;
        flex-shrink: 0;
        flex-grow: 0;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 80% 80%;

        background-color: var(--checkboxBackgroundColor);
      }

      &.error {
        &::before {
          @include error();
        }
      }
    }

    &:checked {
      + label::before {
        border-color: var(--checkboxCheckedBorderColor);
        background-color: var(--checkboxCheckedBackgroundColor);
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9' height='7' viewBox='0 0 9 7' fill='none'%3E%3Cpath d='M2.43001 6.82922L0.26001 4.64923L0.85001 4.0592L2.43001 5.63922L7.86001 0.199219L8.46001 0.789185L2.43001 6.82922Z' fill='%23F9F9F9'/%3E%3C/svg%3E");
      }
    }
  }

  @include customCheckboxSize(10px);
  @include customCheckboxBorderRadius(4px);
  @include customCheckboxBorderColor(var(--checkboxBorderColor));
}
