@import 'src/styles/variables';

.custom-loader {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background:
    radial-gradient(farthest-side, #cfd6d8 94%, #0000) top/16px 16px no-repeat,
    conic-gradient(#0000 30%, #cfd6d8);
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 16px), #000 0);
  animation: s3 1s infinite linear;
}

@keyframes s3 {
  100% {
    transform: rotate(1turn);
  }
}

.UiLoader {
  &.centered {
    display: flex;
    justify-content: center;
  }
}

.btn {
  height: $inputHeight;
  display: flex;
  align-items: center;
  justify-content: center;

  .custom-loader {
    width: $inputHeight;
    height: $inputHeight;
  }
}

.fullScreen {
  width: 100vw;
  height: 100vh;
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  background-color: #091e313d;
  overflow: hidden;
  z-index: 99999;
}

.fullPageLoader {
  position: relative;

  .UiLoader {
    width: calc(100% - #{$bodyPadding} * 2);
    height: calc(100% - #{$bodyPadding} * 2);
    position: absolute;
    justify-content: center;
    display: flex;
    align-items: center;
  }
}
