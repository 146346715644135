@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
  width: 100%;
  background: #eceef0;
}

.widgets {
  margin-left: 27px;
  display: grid;
  gap: 14px;
  margin-top: 14px;
}
