@import 'src/styles/variables';
@import 'src/styles/mixins';

.googleBtn {
  background-color: #5383ec !important;
  display: grid !important;
  grid-template-columns: 40px 1fr;
  padding: 0 2px;

  border-radius: 4px !important;

  :global {
    div {
      background-color: #fff;
      width: 31px;
      height: 31px;
      padding: 6px;

      @include border-radius(4px);
    }
  }
}
