@import 'src/styles/variables';
@import 'src/styles/mixins';

.todoBlock {
  $collapseIconWidth: 13px;
  $gap: 14px;

  .top {
    display: grid;
    grid-template-columns: $collapseIconWidth 1fr;
    gap: $gap;
    align-items: center;
    margin-bottom: 10px;

    .collapseIcon {
      width: 13px;
      cursor: pointer;
    }

    .collapseIconOpen {
      transform: rotate(180deg);
    }

    .header {
      font-size: 24px;
      font-weight: $mainFontWeight;
    }
  }

  .center {
    display: grid;
    grid-template-columns: $collapseIconWidth 1fr;
    gap: $gap;
    align-items: center;

    .content {
      display: grid;
      gap: 13px;
      overflow: auto;
      padding-right: 10px;

      .linedText {
        position: relative;
        z-index: 0;
        overflow: hidden;
        text-align: center;
        font-size: 12px;
        font-weight: $mainFontWeight;

        :global {
          svg {
            width: 8px;
            margin-left: 5px;
          }
        }

        &::before,
        &::after {
          content: '';
          display: inline-block;
          width: 100%;
          height: 1px;
          background: $borderColor;
          z-index: -1;
          position: relative;
          vertical-align: middle;
        }

        &::before {
          right: 6px;
          margin: 0 0 0 -100%;
        }

        &::after {
          left: 6px;
          margin: 0 -100% 0 0;
        }
      }

      .item {
        height: 50px;
        background-color: #fff;
        display: grid;
        grid-template-columns: 24px 1fr 102px 174px 90px;
        gap: 15px;
        justify-content: left;
        align-items: center;
        padding-right: 11px;
        padding-left: 11px;
        font-size: 12px;
        font-weight: $mainFontWeight;

        @include border-radius($borderRadius);

        &.createNew {
          cursor: pointer;

          :global {
            svg {
              width: 26px;
              height: 26px;
            }
          }
        }

        .name {
          @include nowrap-ellipsis;

          cursor: pointer;
        }

        .done {
          :global {
            .checkboxWrapper .UiInput.checkbox + label::before {
              width: 24px;
              height: 24px;

              @include border-radius(50%);
            }
          }
        }

        .timer {
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;

          :global {
            svg {
              height: 10px;
              margin-left: 6px;
            }
          }
        }

        .timerStart {
          font-size: 12px;
          font-weight: $mainFontWeight;

          :global {
            svg {
              path {
                fill: $mainColor;
              }
            }
          }
        }

        .timerStop {
          font-size: 12px;
          font-weight: 700;
          color: #ff003d;

          :global {
            svg {
              width: 20px;
              height: 20px;
            }
          }
        }
      }
    }
  }
}
