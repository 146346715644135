@import 'src/styles/variables';
@import 'src/styles/mixins';

.cursor-pointer {
  cursor: pointer;

  * {
    cursor: pointer;
  }
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.bodyPadding {
  padding: $bodyPadding;
}

.float-right {
  float: right !important;
}

.img-circle {
  display: block;
  flex-shrink: 0;
  object-fit: cover;
  border-radius: 50%;
}

.d-none {
  display: none !important;
}

.nowrap-ellipsis {
  @include nowrap-ellipsis();
}

.disabled-input {
  &:focus {
    border: 1px solid $borderColor !important;
    outline: none !important;
    box-shadow: none !important;
  }
}

.disabled {
  cursor: default;
  pointer-events: none;
}
