@import 'src/styles/variables';
@import 'src/styles/mixins';
@import 'src/styles/helpers';

.layout {
  display: grid;
  grid-template-rows: auto 65vh 1fr;
}

.widgets {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 28px;

  .baseWidget {
    height: 434px;
    width: 100%;
    border: 1px solid #c6c6c6;
    font-size: 12px;
    font-style: normal;
    font-weight: $mainFontWeight;
    line-height: normal;
    text-align: center;
    overflow: hidden;
    display: grid;
    align-items: center;

    @include border-radius(14px);

    & + & {
      margin-top: initial;
    }
  }
}
