@import 'src/styles/variables';

@mixin setColor($bgColor, $color) {
  background-color: $bgColor !important;
  border-color: $bgColor !important;

  :global {
    .react-select__single-value {
      color: $color;
      text-align: center;
    }

    .react-select__indicator {
      color: $color;
    }
  }
}

.NotStarted {
  @include setColor(var(--taskStatusNotStarted), $mainColor);
}

.InProgress {
  @include setColor(var(--taskStatusInProgress), $mainColor);
}

.Completed {
  @include setColor(var(--taskStatusCompleted), $mainColorOpposite);
}

.InReview {
  @include setColor(var(--taskStatusInReview), $mainColorOpposite);
}
