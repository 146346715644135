@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
  background: #fff;
  padding: 8px 15px;

  @include border-radius($borderRadius);

  :global {
    .UiLoader {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
