@import 'src/styles/variables';
@import 'src/styles/mixins';

.projectsOverview {
  .UiLoader {
    align-items: end !important;
  }

  &__container {
  }

  .projectsCard {
    text-decoration: none;
    color: $borderColor;
    justify-content: center;

    &--content {
      display: flex;
      //height: 100%;
      margin: 12px 0;
      width: calc(100% - 10px * 2);
      flex-direction: column;
      align-items: baseline;
      gap: 3px;
    }

    &--label {
      @include border-radius($borderRadius);
      color: $mainColorOpposite;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      font-weight: $mainFontWeight;
      padding: 2px 5px;
      line-height: 12px;
    }

    &--header {
      font-size: 18px;
      font-weight: $mainFontWeight;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 100%;
    }

    &--footer {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-top: 12px;

      button {
        padding: 8px 20px 8px 10px;

        svg {
          margin-right: 8px;
        }
      }
    }

    &--phase {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 100%;

      span {
        font-weight: 400;
      }
    }

    &--text {
      font-weight: 400;
    }
  }

  &__item {
    &--new {
      cursor: pointer;
      border-color: $borderColor !important;
      justify-content: center;

      svg {
        margin-bottom: 14px;
      }
    }
  }
}

.timelineGant {
  margin-left: -$bodyPadding;
  margin-right: -$bodyPadding;
  margin-bottom: -$bodyPadding;
  height: calc(100vh - 1rem - #{$inputHeight} - #{$bodyPadding});
}

.projectLayoutView {
  display: grid;
  grid-template-columns: 1fr minmax(150px, 32%);

  gap: 28px;

  .action-buttons {
    // justify-self: end;
    .UiInput-wrapper {
      width: 408px;
    }
    display: flex;
    gap: 10px;
    .editButton {
      padding: 5px 0 5px 5px;
      height: 33.24px;
      display: flex;
      justify-content: center;
      align-items: center;
      @extend .cursor-pointer;
      @include border-radius($borderRadius);
    }
    .deleteProjectWrapper {
      .deleteProject {
        width: 36.65px;
        height: 33.24px;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
          svg path {
            fill: #ea0647;
          }
        }

        @extend .cursor-pointer;
        @include border-radius($borderRadius);
      }
    }
  }
}
