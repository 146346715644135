@import 'src/styles/variables';
@import 'src/styles/mixins';
@import 'src/styles/helpers';

.wrapper {
  height: 100%;
  display: grid;
  grid-template-rows: 1fr 140px;

  :global {
    .wysiwyg {
      margin-bottom: 0 !important;
      align-self: end;
    }

    .UiInput {
      padding: 0 !important;
    }
  }

  .messages {
    display: grid;
    overflow: auto;
    grid-template-rows: 1fr 1px;

    .scrollfix {
      height: 1px;
      background: #fff;
      position: relative;
      top: -1px;
    }

    .message {
      display: grid;
      grid-template-columns: 37px 1fr;
      gap: 0 21px;
      width: calc(100% - 20px);
      margin-bottom: 20px;

      .logoWrapper {
        display: flex;
        align-self: baseline;
        grid-row: 1/3;

        img {
          position: relative;
          object-fit: cover;
          width: 100%;
          @include border-radius(50%);
        }
      }

      &:last-of-type {
        margin-bottom: 0;
      }

      .top {
        display: flex;
        gap: 12px;
        margin-bottom: 6px;
        align-items: center;

        .name {
          font-weight: $mainFontWeight;
          font-size: 14px;
          max-width: 50%;

          @include nowrap-ellipsis();
        }

        .date {
          font-size: 10px;
          font-weight: 400;
        }
      }

      &:hover .comment .actions {
        visibility: visible;
      }

      .comment {
        display: flex;

        .text {
          font-size: 12px;
          font-weight: 400;
          line-height: normal;
          word-break: break-word;

          p {
            margin: 0;

            img {
              max-width: 90%;
            }
          }

          :global {
            .fr-emoticon {
              background-repeat: no-repeat !important;
              width: 18px;
              display: inline-block;
            }
          }
        }

        .actions {
          visibility: hidden;
          align-self: center;
          margin-left: auto;
          width: 54px;
          min-height: 25px;
          background-color: #eceef0;
          border-radius: $borderRadius;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-shrink: 0;

          .action {
            @extend .cursor-pointer;
          }
        }
      }
    }
  }

  .chat {
    :global {
      .fr-newline {
        background: red !important;
        height: 0 !important;
      }

      .fr-btn-grp {
        margin: 0 0 0 6px !important;

        .fr-btn svg.fr-svg {
          height: 18px;
          width: 18px;
        }
      }
    }
  }

  .submit {
    position: absolute;
    bottom: 10px;
    right: 20px;
    z-index: 99;
    width: 81px;
    height: 23.5px;
  }
}
