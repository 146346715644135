@import 'variables';
@import 'src/styles/mixins';
@import 'src/styles/variables';

// override to customize this
.UiInput-wrapper.fileUploader {
  $height: 36px;

  .fileGrid {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 11px;

    .fileGridItem {
      height: $height;
      display: flex;
      justify-content: left;
      align-items: center;
      border: 1px solid $borderColor;
      @include border-radius($borderRadius);
      padding: 5px;
      text-decoration: none;
      @extend .cursor-pointer;
      background-color: $mainColorOpposite;

      &.disabled {
        cursor: default;
        pointer-events: none;
      }

      svg:not(.fileGridItem_delete-icon) {
        width: 29px;
        height: 27px;
        margin-right: 5px;
      }

      &_text {
        @include nowrap-ellipsis();

        font-size: 11px;
        font-weight: $mainFontWeight;
        line-height: 16px;
      }

      &_delete-icon {
        margin-left: 5px;
      }
    }
  }

  .UiInput {
    height: $height;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $mainColorOpposite;

    &.disabled {
      background: darken($mainColorOpposite, 20%);
    }
  }
}
