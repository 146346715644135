@import 'src/styles/variables';

.content {
  width: 430px;
  height: 384px;

  :global {
    h2 {
      font-size: 24px;
      font-weight: $mainFontWeight;
      margin-bottom: 0;
    }

    svg {
      width: 20px;
      height: 20px;
      margin-left: 10px;
    }

    p {
      font-size: 17px;
      font-weight: $mainFontWeight;
      margin-bottom: 0;
      line-height: 18px;
      width: 315px;
    }

    button {
      margin-top: 12px;
      padding: 0 33px;
      width: 240px;
    }
  }

  .bodyContent {
    justify-content: center;
    height: 268px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 19px;
  }
}
