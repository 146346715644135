@import 'src/styles/variables';

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 475px;

  .mainInfo {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 100px;

    .billTo,
    .invoice {
      flex-basis: 0;
      flex-grow: 1;
    }

    .mainInfoHeader {
      font-size: 12px;
      font-weight: $mainFontWeight;
      color: #4e616a;
      text-align: left;
      margin-bottom: 4px;
    }

    .mainInfoText {
      text-align: left;
      font-size: 12px;
      font-weight: 400;
      color: #4e616a;
      margin: 0;
      white-space: pre;
    }
  }

  table {
    width: 80%;
    border-collapse: collapse;
    margin: 20px 0;

    tr {
      text-align: left;
      border-bottom: 1px solid #455a63;

      td {
        max-width: 168px;
        overflow: hidden;
        text-overflow: ellipsis;
        border-right: 1px solid #455a63;

        &:last-child {
          border-right: none;
        }
      }
    }
  }

  .totalInfo {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    p {
      position: relative;
      margin-bottom: 2px;
      text-align: left;
      display: flex;
      width: 165px;
      justify-content: space-between;

      &:after {
        position: absolute;
        right: 0;
        bottom: 0;
        display: block;
        content: '';
        width: 165px;
        height: 1px;
        background: #455a63;
      }
    }
  }

  .note {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;

    p {
      margin-bottom: 4px;
      text-align: left;
    }

    &:after {
      position: absolute;
      left: 0;
      bottom: 0;
      display: block;
      content: '';
      width: 90%;
      height: 1px;
      background: #c6c6c6;
    }
  }

  .buttonBlock {
    width: 100%;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 11px;

    button {
      &:first-child {
        width: 103px;
      }

      &:last-child {
        width: 188px;
      }
    }
  }
}
