@import 'src/styles/mixins';
@import 'src/styles/variables';
@import 'src/styles/variablesOverride';

.results {
  background-color: #fff;
  width: 100%;
  margin-top: 45px;
  max-height: 515px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.06);
  border: 1px solid $borderColor;
  position: absolute;
  z-index: 99;

  .block {
    padding: 15px;
  }

  @include border-radius($borderRadius);

  .entity {
    .title {
      font-size: 20px;
      font-weight: $mainFontWeight;
      padding: 0 6px;
      display: flex;
      justify-content: left;
      align-items: center;

      :global {
        svg {
          width: 22px;
          height: 22px;
          position: relative;
          left: 0;
          top: 0;
          margin-right: 10px;
        }
      }
    }

    .items {
      .item {
        font-size: 12px;
        font-weight: 400;
        padding: 6px;
        cursor: pointer;

        &:nth-child(odd) {
          background-color: $table-striped-bg;
        }

        @include nowrap-ellipsis();

        &:hover {
          background-color: #0000001a;
        }
      }
    }
  }

  .showMore {
    padding: 10px 15px;
    font-size: 12px;
    font-weight: 700;
    border-top: 1px solid $borderColor;

    :global {
      a {
        text-decoration: none;
        color: $borderColor;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      svg {
        position: relative;
        left: 0;
        top: 0;
      }
    }
  }
}
