@import 'src/styles/variables';

@function important($important) {
  @return #{if($important, ' !important', '')};
}

@mixin border-radius($radius) {
  border-radius: $radius;
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
}

@mixin transformY($value) {
  -webkit-transform: translateY($value);
  -o-transform: translateY($value);
  transform: translateY($value);
}

@mixin error {
  border-color: red;
}

@mixin customScrollbar() {
  scrollbar-width: thin;
  scrollbar-color: $borderColor;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px transparent;
    border-radius: $borderRadius;
  }

  &::-webkit-scrollbar-thumb {
    background: $borderColor;
    border-radius: $borderRadius;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: transparent;
  }
}

@mixin nowrap-ellipsis() {
  white-space: nowrap;
  overflow: hidden;
  -ms-text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
