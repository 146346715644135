@import 'mixins';
@import 'normalize.css';
@import 'variables';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/variables-dark';
@import 'variablesOverride';
@import '~bootstrap/scss/maps';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/grid';
@import '~bootstrap/scss/reboot';
@import '~bootstrap/scss/root';
@import '~bootstrap/scss/popover';
@import '~bootstrap/scss/transitions';
@import 'src/pages/Projects/styles';
@import 'inputs';
@import 'button';
@import 'components';
@import 'helpers';
@import 'calendar';

:root {
  --bs-body-color: #{$borderColor};
  --taskStatusNotStarted: #{$taskStatusNotStarted};
  --taskStatusInProgress: #{$taskStatusInProgress};
  --taskStatusCompleted: #{$taskStatusCompleted};
  --taskStatusInReview: #{$taskStatusInReview};
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: $mainFontWeight;
}

main {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
}

.content-wrapper {
  max-height: 100vh;
  overflow: auto;
  overflow-x: clip;
  width: 100%;
  display: grid;
}

html {
  color: $mainColor;
}

body {
  font-family: Poppins, sans-serif;
  font-size: 14px;
}

/** sidebar */
.sidebar {
  font-size: 12px;
  font-weight: $mainFontWeight;

  .ps-menu-label {
    line-height: 20px;
  }

  .hr {
    background: $mainColor;
    border: none;
    height: 1px;
    margin: 26px 0;
  }

  .createProjectIcon {
    background-color: #00c712;
    border-radius: 16px;
    color: #fff;

    svg {
      margin-left: -3px;

      path {
        fill: #fff;
      }
    }
  }

  .teamIcon {
    svg {
      height: 26px;
      margin-left: 2px;
    }
  }

  .sidebar-header {
    gap: 16px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: initial;
    margin: 29px 15px 40px;

    .popoverWrapper {
      min-width: 46px;
    }

    div {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    p {
      margin: 0;
    }
  }
}

.logOutPopoverWrapper {
  .popover-body {
    padding: 0;
  }

  .logOutPopover {
    padding: 12px 26px;
    font-size: 14px;
    font-weight: $mainFontWeight;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    button {
      font-size: 12px;
      font-weight: $mainFontWeight;
      height: 25px;
      margin-top: 10px;
      padding: 6px 21px;
    }
  }
}

/** end sidebar */

.topMenu {
  $itemWidth: 120px;
  $borderWidth: 1px;

  height: $inputHeight;
  border: $borderWidth solid $borderColor;

  @include border-radius($borderRadius);

  margin-bottom: 1rem;
  overflow: hidden;
  background: $mainColor;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(auto-fill, $itemWidth);

  &--items2 {
    width: $itemWidth * 2 + $borderWidth * 2;
  }

  &--items3 {
    width: $itemWidth * 3 + $borderWidth * 2;
  }

  &__item {
    display: flex;
    justify-content: center;
    height: $inputHeight;
    align-items: center;
    border-left: 1px solid $borderColor;
    text-decoration: none;
    color: $mainColor;
    background: #fff;

    &--active {
      background: $mainColor;
      color: $mainColorOpposite;
    }

    &:first-of-type {
      border-left: none;
    }
  }
}

$gridItemWidth: 320.58px;

.gridBlock {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax($gridItemWidth, 1fr));
  gap: 20px;
}

.gridItem {
  border: 1px solid #c6c6c6;
  font-size: 12px;
  font-style: normal;
  font-weight: $mainFontWeight;
  line-height: normal;
  text-align: center;
  overflow: hidden;

  @include border-radius(14px);

  display: flex;
  width: $gridItemWidth;
  min-height: 160px;
  flex-direction: column;
  align-items: center;
}

// scrollbars
.track-vertical {
  position: absolute;
  width: 5px !important;
  right: 2px;
  bottom: 2px;
  top: 2px;

  @include border-radius(14px);

  .thumb-vertical {
    background-color: $borderColor;

    @include border-radius(14px);

    width: 5px;
  }
}

.linkButtonLike {
  @extend .button;

  text-decoration: none;
}

.avatarUploader {
  display: flex;
  justify-content: left;
  align-items: flex-start;

  img {
    width: 67.76px !important;
    height: 67.76px !important;
    border-radius: 50% !important;

    @extend .img-circle;
  }

  button {
    background-color: $borderColor !important;
    width: 67.76px !important;
    height: 67.76px !important;
    border-radius: 50% !important;
  }
}

.bodyLocked {
  overflow: hidden;
}

.upcomingWidget {
  &.widget {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 8px 8px;
  }

  .header {
    font-size: 16px;
    font-weight: $mainFontWeight;
  }

  .period {
    display: flex;
    flex-direction: column;
    width: 100%;

    &.later {
      .name {
        opacity: 0.7;
      }
    }

    .periodHeader {
      font-size: 12px;
      font-weight: $mainFontWeight;
      align-self: center;
      line-height: 26px;
    }

    .periodBlocks {
      display: grid;
      gap: 5px;

      .periodBlock {
        font-size: 12px;
        font-weight: $mainFontWeight;
        background-color: #eceef0;
        color: $mainColor;
        display: grid;
        grid-template-columns: 10px 1fr 50px 12px;
        justify-content: center;
        align-items: center;
        width: 100%;
        gap: 4px;
        padding: 7px;
        cursor: pointer;

        @include border-radius($borderRadius);

        .color {
          width: 8px;
          height: 8px;
          background-color: $borderColor;
          border-radius: 50%;
        }

        .name {
          text-align: left;

          @include nowrap-ellipsis;
        }

        svg {
          width: 12px;
          height: 12px;
        }
      }
    }
  }
}
