@import 'src/styles/mixins';

.buttonCheckbox {
  $gap: 10px;

  display: grid;
  // todo customize
  grid-template-columns: repeat(2, calc((100% / 2) - (#{$gap} / 2)));
  width: 100%;
  gap: $gap;

  &.error {
    .button {
      @include error();
    }
  }
}
