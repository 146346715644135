@import 'src/styles/mixins';
@import 'src/styles/inputs/checkbox';

.modal {
  .body {
    max-width: 908px;
    padding: 12px 31px 53px 31px;
    height: 100%;
    overflow: hidden;
  }
  .content {
    height: 627px;
    max-height: 627px;
    width: 908px;
  }
  .bodyContent {
    padding: 0;
    height: 100%;
  }
}

.wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  gap: 27px;
  height: 100%;
  overflow: hidden;
  .divider {
    width: 2px;
    background-color: #455a63;
    height: 480px;
    margin-top: 11px;
  }
  .form,
  .itemsToBeBilled {
    width: 50%;
  }

  :global {
    .UiInput-wrapper {
      width: initial !important;
      margin-bottom: 8px !important;

      &.checkboxWrapper {
        @include customCheckboxSize(16px);
      }
    }

    .UiInput.dropdown {
      .react-select {
        &__option {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }

  .customerNameInput {
    height: 105px !important;
    resize: none;
  }
  .itemsToBeBilled {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    button {
      width: 250px;
    }
  }
}
