@import 'src/styles/helpers';

.wrapper {
  :global {
    svg {
      position: absolute;
      left: 10px;
      top: 10px;
    }

    .UiInput {
      padding-left: 28px !important;
      border-radius: 16px !important;
    }
  }
}
