@import 'src/styles/variables';
@import 'src/styles/mixins';

.content {
  //border: 1px solid $borderColor;
  height: 705px;
  padding: 30px 46px;
  align-self: center;
  justify-self: center;
  width: 425px;
  box-shadow: 0 0 20px 6px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 0 0 20px 6px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0 0 20px 6px rgba(0, 0, 0, 0.25);

  @include border-radius($borderRadius);

  .iconLogo {
    width: 100%;
    height: 39px;
  }

  .delimiter {
    width: 100%;
    border-bottom: 1px solid $mainColor;
    text-align: center;
    margin: 10px 0 25px;

    :global {
      span {
        background-color: #fff;
        padding: 0 10px;
        color: $mainColor;
        position: relative;
        top: 11px;
      }
    }
  }

  :global {
    h1 {
      font-size: 24px;
      font-weight: $mainFontWeight;
      text-align: center;
      margin-bottom: 0;
    }

    .UiInput-wrapper {
      margin-bottom: 12px;
    }

    button,
    .linkButtonLike {
      width: 100%;
      font-size: 14px;
      font-weight: $mainFontWeight;
    }

    .linkButtonLike {
      margin-top: 10px;
    }
  }

  .stepCreate {
    height: 100%;
    display: grid;
    grid-template-rows: 80px 40px 1fr;

    :global {
      form {
        display: grid;
        align-items: center;
        height: 100%;
      }
    }
  }
}

.stepDetails {
  .head {
    display: grid;
    grid-template-columns: 20px 1fr;
    align-items: center;
  }

  :global {
    form {
      margin-top: 30px;
    }
  }
}

.productCard {
  margin-top: 50%;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  text-align: center;

  .priceInfo {
    .price {
      font-size: 28px;
      font-weight: bold;
      color: #333;
    }

    .pricePerUser {
      font-size: 14px;
      color: #666;
      margin-top: 5px;
    }
  }
}

.startTrialButton {
  font-size: 16px;
  cursor: pointer;
}
