@import 'src/styles/helpers';
@import 'src/styles/variables';

.wrapper {
  display: grid;
  grid-template-columns: 265px 365px 100px;
  gap: 50px;

  .personalWrapper {
    display: grid;

    .avatarWrapper {
      margin-top: 60px;
      margin-bottom: 30px;

      :global {
        .avatarUploader {
          button {
            width: 101px !important;
            height: 101px !important;
          }

          img {
            width: 101px !important;
            height: 101px !important;
          }
        }
      }
    }

    .editBtn {
      width: 100px;
    }

    .saveBtn {
      width: 100px;
      justify-self: right;
    }

    .textarea {
      height: 82px;
    }

    :global {
      .UiInput-wrapper {
        margin-bottom: 15px;

        label {
          font-size: 12px;
        }
      }
    }
  }

  .integrationsWrapper {
    margin-top: 206px;
    display: flex;
    flex-direction: column;

    .row {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }

    button {
      margin-top: 23px;
      margin-left: 16px;
      width: 100px;
      font-size: 12px;
      font-weight: $mainFontWeight;
    }

    :global {
      .UiInput-wrapper {
        margin-bottom: 15px;
        width: 268px;

        label {
          font-size: 12px;
        }
      }
    }
  }

  .deleteWrapper {
    margin-top: 206px;

    :global {
      p {
        font-size: 12px;
        line-height: 24px;
        font-weight: $mainFontWeight;
        margin-bottom: 0;
      }

      button {
        width: 100px;
        font-size: 12px;
        font-weight: $mainFontWeight;
      }
    }
  }
}
