@import 'src/styles/variables';
@import 'src/styles/variablesOverride';
@import 'src/styles/mixins';
@import 'src/styles/helpers';

.widget {
  flex-direction: column;
  display: flex !important;
  overflow-y: auto !important;

  :global {
    .UiLoader {
      height: 374px;
    }
  }

  .header {
    font-size: 18px;
    font-weight: $mainFontWeight;
    margin-bottom: 10px;
  }

  .hr {
    background-color: 1px solid $table-border-color;
    height: 1px;
    width: 100%;
  }

  .teamIcons {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 8px;

    .add {
      width: 38px;
      height: 38px;
      padding: 6px;
      cursor: pointer;
    }
  }

  :global {
    .upcomingWidget {
      width: 100%;
    }
  }
  .invoicing {
    overflow: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
    p {
      font-weight: $mainFontWeight;
      font-size: 18px;
      color: $borderColor;
      overflow: hidden;
      svg {
        width: 27px;
        height: 27px;
      }
    }
    .invoicingNameBtn {
      background: none;
      border: none;
      outline: none;
    }
    .createInvoiceBtn {
      cursor: pointer;
    }
  }
  .table {
    &:hover {
      --bs-table-hover-bg: none;
    }
    tbody {
      tr {
        border: 1px solid $table-border-color;
        border-right: none;
        td {
          --bs-table-bg-type: none;
          border-right: 1px solid $table-border-color;
          div {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 4px;
            .downloadIcon {
              cursor: pointer;
              path {
                fill: none;
                &:last-child {
                  fill: $table-border-color;
                }
              }
            }
          }
          &:last-child {
            border-right: none;
          }
        }
      }
    }
  }
}
