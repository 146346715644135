@import 'src/styles/mixins';

.colorPickerPopup {
  position: absolute;
  z-index: 2;
  margin-top: -15px;
}

.colorPickerPreview {
  width: 9px;
  height: 9px;
  left: 6px !important;
  top: 40px !important;

  @include border-radius(50%);
}

.UiInput.color {
}

.github-picker {
  box-sizing: content-box;
}
