@import 'src/styles/variables';
@import 'src/styles/mixins';

.wrapper {
  height: 705px;
  padding: 30px 46px;
  align-self: center;
  justify-self: center;
  width: 425px;
  box-shadow: 0 0 20px 6px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 0 0 20px 6px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0 0 20px 6px rgba(0, 0, 0, 0.25);

  @include border-radius($borderRadius);

  display: grid;
  grid-template-rows: 80px 80px 1fr;

  .iconLogo {
    width: 100%;
    height: 39px;
  }

  .head {
    display: grid;
    grid-template-columns: 20px 1fr;
    align-items: baseline;
  }

  :global {
    h1 {
      font-size: 24px;
      font-weight: $mainFontWeight;
      text-align: center;
      margin-bottom: 0;
    }

    .UiInput-wrapper {
      margin-bottom: 12px;
    }

    button {
      width: 100%;
      font-size: 14px;
      font-weight: $mainFontWeight;
    }
  }
}
