.modal {
  width: 451px !important;
}

.submit {
  width: 250px;
  justify-self: center;
}

.body {
  padding-bottom: 0;
}
