@import 'src/styles/variables';
@import 'src/styles/mixins';

.container {
  margin: 0 #{-$bodyPadding} #{$bodyPadding} 0;

  //width: calc(100% + #{$bodyPadding});
  width: 100%; // no padding to enable grid

  @include customScrollbar;
}
