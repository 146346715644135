$mainColor: #3f5b64;
$mainColorOpposite: #fff;
$borderColor: #455a63;
$borderRadius: 8px;
$inputHeight: 35px;
$bodyPadding: 30px;
$green: #00c712;
$red: #e01230;
$readUnread: #f91515;

$taskStatusNotStarted: '#eceef0';
$taskStatusInProgress: '#ffd731';
$taskStatusCompleted: $mainColor;
$taskStatusInReview: '#6CAFD3';

$mainFontWeight: 500;
