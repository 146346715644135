@import 'flatpickr/dist/themes/material_blue.css';
@import 'src/styles/variables';

/* overriding colors from .css */

.flatpickr-calendar.arrowTop:after {
  border-bottom-color: $borderColor;
}

.flatpickr-calendar.arrowBottom:after {
  border-top-color: $borderColor;
}

.flatpickr-months .flatpickr-month {
  background: $borderColor;
}

.flatpickr-current-month .flatpickr-monthDropdown-months {
  background: $borderColor;
}

.flatpickr-current-month .flatpickr-monthDropdown-months .flatpickr-monthDropdown-month {
  background-color: $borderColor;
}

.flatpickr-weekdays {
  background: $borderColor;
}

span.flatpickr-weekday {
  background: $borderColor;
}

.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
  background: $borderColor;
  border-color: $borderColor;
}

.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n + 1)),
.flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n + 1)),
.flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n + 1)) {
  -webkit-box-shadow: -10px 0 0 $borderColor;
  box-shadow: -10px 0 0 $borderColor;
}

.flatpickr-day.week.selected {
  border-radius: 0;
  -webkit-box-shadow:
    -5px 0 0 $borderColor,
    5px 0 0 $borderColor;
  box-shadow:
    -5px 0 0 $borderColor,
    5px 0 0 $borderColor;
}
