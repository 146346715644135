@import 'src/styles/helpers';
@import 'src/styles/variables';

.wrapper {
  position: relative;

  .img {
    @extend .img-circle;

    background: $mainColor;
  }

  .imgEmpty {
    @extend .img-circle;

    background: $mainColor;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .unread {
    width: 11px;
    height: 11px;
    background-color: $readUnread;
    position: absolute;
    top: 0;
    right: 0;

    @include border-radius(50%);
  }
}
