@import 'src/styles/mixins';
@import 'src/styles/variables';
@import 'src/styles/variablesOverride';

.wrapper {
  max-width: 80%;
}

.results {
  background-color: #fff;
  width: 100%;

  .entity {
    .title {
      font-size: 20px;
      font-weight: $mainFontWeight;
      padding: 10px 6px;
      display: flex;
      justify-content: left;
      align-items: center;

      :global {
        svg {
          width: 22px;
          height: 22px;
          position: relative;
          left: 0;
          top: 0;
          margin-right: 10px;
        }
      }
    }

    .items {
      .item {
        font-size: 12px;
        font-weight: 400;
        padding: 6px;
        cursor: pointer;

        &:nth-child(odd) {
          background-color: $table-striped-bg;
        }

        @include nowrap-ellipsis();

        &:hover {
          background-color: #0000001a;
        }
      }
    }
  }
}
