@import 'src/styles/variables';
@import 'src/styles/mixins';

:global {
  #ganntWrapper {
    position: relative;
  }
}

.container {
  $dateCellWidth: 124px;
  $paddingTopBottom: 8px;

  width: 100%;
  height: 100%;
  font-family: Poppins, sans-serif;

  :global(.gantt_layout_root) {
    border-top-color: $borderColor;
  }

  :global(.gantt_hor_scroll) {
    bottom: 2px;
    min-height: 10px;
  }

  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    background: transparent;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: $mainColor;
    border-radius: $borderRadius;
    cursor: pointer;
  }

  :global(.gantt_layout_cell) {
    border-right-color: $borderColor;
  }

  :global(.gantt_task) {
    background-color: #f8f8f8;
  }

  /* Grid section */
  :global(.gantt_grid) {
    &::before {
      content: '';
      display: block;
      height: 100%;
      width: 1px;
      background-color: $borderColor;
      position: absolute;
      right: $dateCellWidth;
      z-index: 1;
    }
  }

  :global(.grid_cell) {
    border-right-color: $borderColor;
  }

  $paddingLeftNameColumn: 32px;
  $paddingRightNameColumn: 12px;
  $paddingLeftDateColumn: 12px;

  .createPlusButton {
    background-image: url('./icons/plus.svg');
    background-size: 24px 24px;
    background-repeat: no-repeat;
    background-position: center;
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  .sortingHeader {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;

    &.asc .icons .icon:nth-child(1),
    &.desc .icons .icon:nth-child(2) {
      opacity: 1;
    }

    .icons {
      pointer-events: none;
      display: flex;
      flex-direction: column;
      gap: 5px;

      .icon {
        width: 8px;
        height: 4px;
        background: url('./icons/chevron.svg');
        background-size: 8px 4px;
        opacity: 0.5;

        &.top {
          transform: rotate(180deg);
        }
      }
    }
  }

  .gridCreateButtonWrapper {
    width: 320px;
    height: inherit;
    border-right: 1px solid $borderColor;
    padding-left: $paddingLeftNameColumn;
    padding-top: 8px;

    .createTaskButton {
      width: 238px;
      height: 38px;
      border-radius: 8px;
      background-color: #00c712;
      color: #fff;
      font-size: 14px;
      font-family: Poppins, sans-serif;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      border: none;
      box-shadow: none;

      span {
        background-image: url('./icons/plusWhite.svg');
        background-size: 18px;
        width: 18px;
        height: 18px;
      }
    }
  }

  .gridHeaderCell {
    font-weight: $mainFontWeight;
    color: $borderColor !important;
    font-size: 14px;

    &:global(.gantt_grid_head_name) {
      padding-left: $paddingLeftNameColumn;
      text-align: left;

      .createPlusButton {
        position: absolute;
        right: $paddingRightNameColumn;
        top: 0;
        bottom: 0;
        margin: auto;
      }
    }

    &:global(.gantt_grid_head_dueDate) {
      padding-left: $paddingLeftDateColumn;
    }
  }

  .gridRow {
    border-bottom: none;

    &:hover,
    &:global(.gantt_selected) {
      background-color: transparent !important;
    }

    :global(.gantt_cell) {
      padding: 0;
      font-size: 14px;
      font-family: Poppins, sans-serif;
      font-weight: normal;
      color: $borderColor;
      text-transform: capitalize;

      &:nth-child(1) {
        padding-left: 21px;
        padding-right: $paddingRightNameColumn;
      }

      &:nth-child(2) {
        padding-left: $paddingLeftDateColumn;
      }
    }

    &.parent {
      :global(.gantt_cell) {
        &:nth-child(1) {
          font-weight: $mainFontWeight;
          padding-left: $paddingLeftNameColumn - 24px;
        }
      }
    }

    &:not(.parent, .isEmptyProject),
    .gridRowProjectName {
      cursor: pointer;
    }

    .gridRowProjectName,
    .gridRowTaskName {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .gridRowTimelineInnerTaskName {
      margin-left: 25px;
    }

    .gridRowSingleProjectInnerTaskName {
      margin-left: 41px;
    }

    .gridRowPhaseName {
      font-weight: $mainFontWeight;
    }

    :global(.gantt_tree_icon) {
      display: none;
    }

    :global(.gantt_tree_content) {
      display: flex;
      align-items: center;
      gap: 8px;
      width: 100%;

      .createPlusButton {
        margin-left: auto;
        flex-shrink: 0;
      }

      .tree {
        background-image: url('./icons/chevron.svg');
        background-size: 16px 32px;
        background-repeat: no-repeat;
        background-position: center;
        width: 32px;
        height: 32px;
        cursor: pointer;
        transform: rotate(-90deg);
        transition: transform 0.3s;
        flex-shrink: 0;

        &.opened {
          transform: rotate(0);
        }
      }
    }
  }

  /* end Grid section */

  /* tasks section */
  .taskRow {
    border-bottom: none;
    background-color: transparent !important;

    :global(.gantt_task_cell) {
      border-right: none;
    }
  }

  .projectTask {
    display: none;
  }

  .baseline,
  .task {
    position: absolute;
    height: 24px;
    border-radius: 13.83px;
    color: #fff;
    font-size: 12px;
    font-family: Poppins, sans-serif;
    font-weight: $mainFontWeight;
    line-height: 24px;
    text-transform: uppercase;
    text-align: center;
    z-index: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 0 8px;
    border: none;

    :global(.gantt_task_drag.task_right) {
      right: 0;
    }

    :global(.gantt_task_drag.task_left) {
      left: 0;
    }
  }

  /* end tasks section */

  /* scale section */
  .topScale {
    border-bottom: none;

    :global(.gantt_layout_cell),
    & > :global(.gantt_layout_cell):nth-child(2) :global(.gantt_layout_content),
    .gridRow {
      height: inherit !important;
    }

    & > :global(.gantt_layout_cell):first-child {
      border-bottom: 1px solid $borderColor;
      height: inherit !important;
    }
  }

  .noScale {
    border-bottom: none;

    &.projectLine {
      :global(.gantt_layout_cell),
      :global(.gantt_layout_content),
      .gridRow {
        height: inherit !important;
      }
    }

    :global(.gantt_grid_scale),
    :global(.gantt_task_scale) {
      display: none;
    }
  }

  :global(.gantt_grid_scale) {
    border-bottom: none;
  }

  :global(.gantt_task_scale) {
    border-bottom: none;
    background-color: transparent;
  }

  .scaleCellWeek {
    display: flex;
    flex-direction: column;
    line-height: 120%;
    padding-top: 14px;
  }

  .scaleRow {
    :global(.gantt_scale_cell) {
      border-right: none !important;
      font-weight: $mainFontWeight;
      color: $borderColor !important;
      font-size: 14px;

      &::before {
        content: '';
        width: 2px;
        height: 14px;
        background-color: $borderColor;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        margin: auto;
      }
    }
  }

  /* end scale section */

  .marker {
    border-right: 2px dashed $borderColor;
    background-color: transparent;
    width: 0;
    height: 100vh !important;
    position: absolute;
    top: 0;
  }

  .loaderWrapper {
    display: flex;
    justify-content: center;
    width: 100%;

    & > :global(.custom-loader) {
      width: 38px;
      height: 38px;
    }
  }
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 99;
}

.zoom {
  position: absolute;
  right: 50px;
  bottom: 50px;
  display: grid;
  grid-template-columns: repeat(3, 60px);
  border: 1px solid $borderColor;
  padding: 4px;
  gap: 6px;
  background-color: $mainColorOpposite;
  z-index: 10;

  @include border-radius($borderRadius);

  .zoomActive {
    background-color: $mainColor;
    color: $mainColorOpposite;
  }

  & > div {
    padding: 6px;
    text-align: center;
    cursor: pointer;

    @include border-radius($borderRadius);

    &:hover {
      background-color: $mainColor;
      color: $mainColorOpposite;
    }
  }
}
