@import 'src/styles/variables';
@import 'src/styles/mixins';
@import 'src/styles/helpers';
@import 'src/styles/inputs/checkbox';

.widgetWrapper {
  display: grid;
  gap: 10px;

  .top {
    display: grid;
    grid-template-columns: 1fr;
    gap: 14px;
    align-items: center;

    .header {
      font-size: 24px;
      font-weight: $mainFontWeight;
    }
  }
}

.wrapper {
  display: grid;
  align-items: center;
  grid-template-columns: repeat(4, 130px) 1fr 200px;
  gap: 20px;

  .infoBlock {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;

    .value {
      font-size: 26px;
      line-height: 36px;
      font-weight: 600;
    }

    .text {
      font-size: 13px;
      line-height: 13px;
      font-weight: $mainFontWeight;
    }
  }

  .button {
    width: 100%;
    font-size: 12px;
    font-weight: $mainFontWeight;
    height: 30px;
  }
}

/** modal */
.modalWrapper {
  width: 834px;

  :global {
    td,
    th {
      padding: 0 !important;
      font-size: 12px;
      font-weight: 600;
    }

    td {
      font-weight: 400;
    }
  }

  .columnDone {
    width: 36px;
  }

  .columnTaskName {
    width: 168px;
    padding: 4px 8px;
    text-align: left;
  }

  .columnAssignee {
    width: 150px;
    padding: 4px 8px;
    text-align: left;
  }

  .columnStatus {
    width: 149px;
    padding: 4px 8px;
  }

  .columnDueDate {
    width: 71px;
    padding: 4px 8px;

    @extend .cursor-pointer;
  }

  .columnTimeLogged {
    width: 94px;
    padding: 4px 8px;

    @extend .cursor-pointer;
  }

  .columnBillable {
    width: 64px;
    padding: 4px 8px;

    @extend .cursor-pointer;
  }
}

/** modal time logs */
.wrapperTimeLogModal {
  padding-right: 100px;
  padding-left: 100px;

  :global {
    svg {
      justify-self: center;
      margin-bottom: 20px;
    }
  }

  .columnDate {
    width: 170px;
  }

  .columnTimeLogged {
    width: 150px;
  }

  .columnAdd {
    display: flex;
    justify-content: center;
    align-items: baseline;
    height: 12px;

    :global {
      svg {
        cursor: pointer;
      }
    }
  }

  .button {
    margin-top: 30px;
    display: flex;
    justify-content: center;

    :global {
      button {
        width: 250px;
      }
    }
  }
}

.addTime {
  padding-bottom: 130px;
}
