@import 'src/styles/mixins';
@import 'src/styles/variables';
@import 'src/styles/inputs/checkbox';

.top {
  display: grid;
  grid-template-columns: minmax(50px, 1fr) minmax(50px, 1fr) 250px;
  gap: 30px;
}

.table {
  :global {
    .additional-right,
    .additional-left {
      border: none !important;
    }

    .additional-left {
      width: 20px;
      padding: 0 0 0 6px;

      .checkboxWrapper {
        margin-bottom: 0;

        @include customCheckboxSize(15px);
        @include customCheckboxBorderRadius(6px);
        @include customCheckboxBorderColor(#c5c5c5);
      }
    }

    td,
    th {
      border-bottom-width: 0 !important;
    }
  }

  .columnName {
    width: 150px;
    text-align: left;

    @include nowrap-ellipsis();

    :global {
      svg {
        width: 18px;
        height: 18px;
        margin-right: 6px;
      }
    }
  }

  .columnOwner {
    width: 100px;
    text-align: left;

    @include nowrap-ellipsis();
  }

  .columnProject {
    width: 120px;
    text-align: left;

    @include nowrap-ellipsis();

    :global {
      a {
        color: $mainColor;
        text-decoration: none;
      }
    }
  }

  .columnTask {
    width: 120px;
    text-align: center;

    @include nowrap-ellipsis();

    :global {
      span {
        cursor: pointer;
      }
    }
  }

  .columnPhase {
    width: 100px;

    @include nowrap-ellipsis();
  }

  .actions {
    display: grid;
    grid-template-columns: repeat(4, 34px);
    gap: 8px;

    :global {
      a {
        margin-top: -1px;
        height: 20px;
      }

      svg {
        cursor: pointer;
      }
    }
  }

  .actionDelete {
    height: 20px;

    :global {
      svg {
        cursor: pointer;
      }
    }
  }
}
