@import 'dropdown';
@import 'variables';
@import 'colorPicker';
@import 'buttonCheckbox';
@import 'checkbox';
@import 'wysiwyg';
@import 'fileUploader';
@import 'text';
@import 'tag';

@import 'src/styles/variables';
@import 'src/styles/mixins';

.UiInput-wrapper {
  flex-direction: column;
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  position: relative;

  .UiInput {
    height: $inputHeight;
    @include border-radius($borderRadius);
    border: 1px solid $borderColor;
    padding: 0 $input_padding;
    width: 100%;

    &.error {
      @include error();
    }

    &:focus,
    &:active,
    &:focus-visible {
      border: 1px solid darken($borderColor, 50%);
      outline: 1px solid $borderColor;
    }

    &.mp-1 {
      width: 100%;
      box-sizing: border-box;
      //height: $inputHeight;
      padding-left: $input_padding * 2;
    }
  }

  label {
    display: flex;
    align-self: baseline;
    margin-bottom: 6px;
    font-weight: $mainFontWeight;
  }

  .prefix {
    position: absolute;
    left: $input_padding;
    top: 31px;
    font-weight: $mainFontWeight;
  }

  .postfix {
    position: absolute;
    right: 10px;
    top: 31px;
    font-weight: $mainFontWeight;
  }
}
