@import 'src/styles/variables';

.modal {
  width: 705px !important;

  .body {
    padding: 23px;

    .inputName {
      height: 45px;
      font-size: 24px;
    }

    .three {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 10px;
    }

    .two {
      display: grid;
      grid-template-columns: 250px 1fr;
      gap: 20px;
    }

    .textarea {
      height: 110px;
    }

    .submitWrapper {
      display: grid;
      grid-template-columns: 33px 1fr;

      .delete {
        width: 36.65px;
        height: 33.24px;
        background-color: #eceef0;
        border-radius: $borderRadius;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .submit {
        justify-self: center;
        align-self: center;
        width: 250px;
      }
    }
  }
}
