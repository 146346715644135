@import 'src/styles/mixins';
@import 'src/styles/variables';
@import '~tributejs/tribute.css';

.fr-tribute {
  // global
  font-weight: 900;

  &::before {
    content: '@';
  }
}

.tribute-container {
  max-width: 277px;
  max-height: 118px;
  border: 1px solid #afafaf;
  background: $mainColorOpposite;

  @include customScrollbar;
  @include border-radius(8px);

  ul {
    background: $mainColorOpposite;
    margin-top: 10px;
    margin-bottom: 10px;

    li {
      display: flex;
      justify-content: left;
      align-items: center;
      padding-left: 15px;
      padding-right: 15px;

      .wrapImg {
        margin-right: 6px;
        text-align: center;
      }

      .img {
        font-size: 12px;

        @extend .img-circle;
      }

      &.highlight {
        background: #e1f5f4;
      }
    }
  }
}

.UiInput-wrapper.wysiwyg {
  margin-bottom: 24px;
  word-break: break-word;

  //overflow: hidden; // blocks popups

  .fr-wrapper {
    box-sizing: content-box;

    @include customScrollbar;
  }

  .UiInput {
    height: auto;
    border: none;
    width: 100%;

    &:focus,
    &:active,
    &:focus-visible {
      border: none;
      outline: none;
    }

    &.focused {
      .fr-box {
        //border: 1px solid darken($borderColor, 50%);
        //outline: 1px solid $borderColor;
      }

      .fr-toolbar,
      .fr-wrapper,
      .fr-second-toolbar {
        //border: none;
        border-color: darken($borderColor, 50%);
      }
    }

    .fr-box.fr-basic .fr-element {
      padding: 10px;

      p {
        margin: 0;
      }
    }

    .fr-toolbar,
    .fr-wrapper,
    .fr-second-toolbar {
      border-color: $borderColor;
    }

    .fr-toolbar {
      .fr-command.fr-btn svg {
        margin: 3px;
      }
    }

    .fr-newline {
      background: $borderColor;
      margin: 0;
    }

    .fr-toolbar {
      margin: 0;

      .fr-btn-grp {
        margin: 0 0 0 2px;

        button {
          margin: 0;
        }
      }
    }

    .fr-placeholder {
      color: $mainColor;
    }

    &.error {
      .fr-toolbar,
      .fr-wrapper,
      .fr-second-toolbar {
        @include error;
      }
    }
  }
}
