@import 'src/styles/variables';
@import 'src/styles/mixins';
@import 'src/styles/helpers';

.widgetWrapper {
  display: grid;
  gap: 10px;

  .top {
    display: grid;
    grid-template-columns: 1fr;
    gap: 14px;
    align-items: center;

    .header {
      font-size: 24px;
      font-weight: $mainFontWeight;
    }
  }
}

.wrapper {
  display: grid;
  grid-template-columns: 180px 400px 1fr 200px;
  align-items: center;
  gap: 20px;

  .week {
    font-size: 16px;
    font-weight: $mainFontWeight;
    width: 85%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .arrow {
      @extend .cursor-pointer;

      &.arrowLeft {
        transform: rotate(180deg);
      }
    }
  }

  .info {
    display: flex;
    flex-direction: row;
    gap: 15px;

    .infoBlock {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 20px;

      .value {
        font-size: 26px;
        line-height: 36px;
        font-weight: $mainFontWeight;
      }

      .text {
        font-size: 13px;
        line-height: 13px;
        font-weight: $mainFontWeight;
      }
    }
  }

  .button {
    width: 100%;
    font-size: 12px;
    font-weight: $mainFontWeight;
    height: 30px;
  }
}

/** modal */
.modalWrapper {
  width: 834px;

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .header {
      flex-direction: column;
      width: 170px;
      display: flex;
      align-items: center;
      justify-self: center;
      margin-bottom: 20px;
    }

    .week {
      font-size: 16px;
      font-weight: $mainFontWeight;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .arrow {
        @extend .cursor-pointer;

        &.arrowLeft {
          transform: rotate(180deg);
        }
      }
    }

    .button {
      width: 250px;
      font-size: 14px;
      font-weight: $mainFontWeight;
      height: 30px;
      margin-top: 20px;
    }

    .ellipsable {
      width: 224px;

      @include nowrap-ellipsis();
    }
  }
}
