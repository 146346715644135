@import 'src/styles/mixins';
@import 'src/styles/inputs/checkbox';

.wrapper {
  $dividerColor: rgba($borderColor, 0.2);
  $inputMarginBottom: 4px;

  width: 805px !important;

  .body {
    padding: 0;
    padding-right: 3px;
    padding-bottom: 3px;
  }

  .bodyContentClassName {
    padding-right: 5px;
  }

  .blocks {
    display: grid;
    grid-template-columns: 40% 1fr;
    text-align: left;

    .block {
      padding: 0 2px 2px;

      &.blockLeft {
        padding-right: 23px;
        padding-top: 10px;
        border-right: 1px solid $dividerColor;

        :global {
          .UiInput-wrapper {
            //margin-bottom: $inputMarginBottom;
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .projectLink {
    margin-left: 5px;
    cursor: pointer;
    position: relative;
    top: -2px;

    :global {
      svg {
        width: 15px;
        height: 15px;
      }
    }
  }

  .blockRight {
    display: grid;
    grid-template-rows: 123px 1fr;
    padding-top: 10px;
  }

  .plusIcon {
    position: absolute;
    right: 10px;
    top: 30px;
  }

  .header {
    font-size: 24px;
    font-weight: $mainFontWeight;
    line-height: 28px;
    text-align: left;
    word-break: break-word;
    padding: 10px 20px 0;

    .status {
      font-size: 10px;
      font-weight: 400;
      color: #d1d1d1;
      border: 1px solid #d1d1d1;
      line-height: 15px;
      padding: 0 7px;
      height: 15px;
      display: inline-flex;
      justify-content: center;
      align-items: center;

      @include border-radius(8px);

      &.billable {
        border-color: $green;
        color: $green;
      }
    }

    .taskName {
      &::after {
        position: absolute;
        left: 0;
        content: ' ';
        display: block;
        height: 10px;
        border-bottom: 1px solid $dividerColor;
        width: 100%;
      }
    }
  }

  .contentP {
    margin-bottom: $inputMarginBottom;

    p {
      font-size: 14px;
      font-weight: $mainFontWeight;
      margin-bottom: 6px;
    }

    &.inputStatus {
      :global {
        .react-select__single-value {
          text-align: left !important;
        }
      }
    }

    &.inputProjectName {
      :global {
        div {
          border: 1px solid $borderColor;
          padding: 6px;

          @include border-radius(8px);
        }
      }
    }

    &.inputPhase {
    }

    &.inputPhaseDates {
      :global {
        div {
          border: 1px solid $borderColor;
          padding: 6px;

          @include border-radius(8px);
        }
      }
    }
  }

  .description {
    word-wrap: break-word;
    border-bottom: 1px solid $dividerColor;
    padding-left: 23px;
    padding-bottom: 23px;
    overflow: hidden;

    .headRight {
      padding-left: 10px;
      padding-bottom: 10px;
      word-break: break-word;

      p {
        margin-bottom: 0;
      }
    }

    :global {
      div {
        overflow-x: hidden !important;
      }

      .wysiwyg {
        margin-bottom: 0;
      }
    }
  }

  .chatWrapper {
    padding-left: 23px;
    padding-top: 23px;
    display: grid;
    grid-template-rows: 1fr 36px;
  }

  .contentHeader {
    padding: 7px 0 0;

    .headLeft {
      padding-right: 22px;
    }
  }

  .contentBody {
    padding: 10px 10px 20px 20px;

    .twoRows {
      display: grid;
      grid-template-columns: 50% 50%;
      gap: 9px;
      align-items: end;
      margin-bottom: $inputMarginBottom;

      .btnStarted {
        background-color: $mainColorOpposite;
        border: 1px solid #ff003d;
        color: #ff003d;
        font-size: 12px;
        font-weight: 700;
      }

      :global {
        .UiInput-wrapper {
          margin-bottom: 0 !important;
        }

        button {
          font-size: 12px;
        }

        svg {
          margin-left: 13px;
        }
      }
    }

    .files {
      margin-bottom: 0 !important;
      margin-top: 30px !important;

      :global {
        .fileGrid {
          grid-template-columns: repeat(3, 31%);

          button.UiInput,
          .fileGridItem {
            height: 50px;
          }
        }
      }
    }
  }

  .actions {
    display: grid;
    justify-content: end;
    margin-top: 15px;

    :global {
      svg {
        cursor: pointer;
        height: 19px;
        width: 17px;

        &:hover {
          path {
            fill: $red;
          }
        }

        path {
          fill: $borderColor;
        }
      }
    }
  }
}
