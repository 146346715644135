@import 'src/styles/variables';

.container {
  height: 100%;
  padding-top: 13px;
  width: 100%;
}
.subHeading {
  font-size: 12px;
  font-weight: $mainFontWeight;
  color: #4e616a;
  text-align: left;
}
.wrapper {
  display: flex;
  flex-direction: column;
  gap: 37px;
  .tableBlock {
    min-height: 165px;
    .tableWrapper {
      max-height: 136px;
      overflow-y: scroll;
      .invoiceTable {
        width: 100%;
        border-collapse: collapse;
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
        input[type='number'] {
          appearance: textfield;
        }
        thead {
          text-align: left;
        }
        th {
          border-bottom: 1px solid #455a63;
          font-size: 12px;
        }
        td {
          border-bottom: 1px solid #455a63;
          font-size: 14px;
          border-right: 1px solid #455a63;
          &:last-child {
            border-right: none;
            border-bottom: none;
          }
          &:nth-child(5) {
            border-right: none;
          }
        }
        .textRightAmount {
          text-align: right;
          font-weight: bold;
          max-width: 48px;
          width: 60px;
          overflow: auto;
          text-overflow: ellipsis;
        }
        tr {
          td {
            padding: 0;
            width: 48px !important;
            &:nth-child(1) {
              width: 17px !important;
            }
            &:nth-child(2) {
              width: 156px !important;
            }
            input {
              border: none;
              font-size: 14px;
              width: 100%;
              &:focus-visible {
                border: none;
                outline: none;
              }
            }
          }
        }
      }
    }
  }
  .addLineBtn {
    width: 100%;
    border: none;
    background: none;
    font-size: 10px;
    font-weight: $mainFontWeight;
    color: #4e616a;
    line-height: 15px;
    text-align: left;
    display: flex;
    gap: 3px;
    margin-top: 10px;
  }
  .totalInfo {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    width: 205px;
    max-width: 205px;
    gap: 7px;
    margin-left: 217px;
    .iconsBlock {
      display: flex;
      flex-direction: column;
      position: relative;
      div {
        display: flex;
        gap: 1px;
        &:first-child {
          position: absolute;
          top: 26px;
        }
        &:last-child {
          position: absolute;
          top: 35px;
        }
        .taxIcon {
          cursor: pointer;
        }
        .taxIconSelected {
          path {
            fill: #00c712;
          }
        }
      }
    }
    .infoBlock {
      max-width: 166px;
      .infoRaw {
        display: flex;
        justify-content: space-between;
        gap: 21px;
        border-bottom: 1px solid black;
        p {
          color: #455a63;
          font-size: 12px;
          font-weight: 400;
          margin: 0;
        }
        .taxInput {
          width: 100%;
          border: none;
          outline: none;
          border-radius: 0;
          padding: 0;
          margin-bottom: 0 !important;
          text-align: right;
          &:disabled {
            background: transparent;
          }
          &:focus-visible {
            border: none;
            outline: none;
          }
        }
      }
    }
  }
}
