@import 'src/styles/variables';
@import '~bootstrap/scss/tables';

.table {
  .thead-dark {
    color: #fff;

    div {
      color: #fff;
    }

    th {
      background-color: $mainColor;
    }
  }

  .col {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  td.additional-right,
  td.additional-left {
    border: 1px solid #fff; // color of background
  }

  th.additional-right,
  th.additional-left {
    border: 1px solid #fff; // color of background
    background: #fff;
  }

  td,
  th {
    text-align: center;
    vertical-align: middle;
    padding: 6px 12px;

    .item-content {
      .UiInput-wrapper {
        width: 100%;
      }
    }

    @mixin textDirection($direction) {
      display: flex;
      flex: 1;
      justify-content: $direction;
      align-items: center;
    }

    .text-left {
      @include textDirection(left);
    }

    .text-center {
      @include textDirection(center);
    }

    .text-right {
      @include textDirection(right);
    }

    .checkboxWrapper .UiInput.checkbox + label span {
      margin-left: 0;
    }
  }

  .sorting {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-left: 5px;

    .icon {
      &.top {
        transform: rotate(180deg);
      }

      &.bottom {
      }

      &.active {
        path {
          opacity: 1;
        }
      }

      &.not-active {
      }
    }
  }

  .table-loading,
  .table-no-data {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
