@import 'src/styles/variables';
@import 'src/styles/mixins';
@import 'src/styles/helpers';
@import 'src/styles/inputs/checkbox';
@import 'src/styles/variablesOverride';

.addTeamMembersWrapper {
  display: grid;
  border: 1px solid $borderColor;

  @include border-radius($borderRadius);

  .addTeamMembers {
    padding: 15px 12px;
    height: 388px;
    overflow: auto;

    .addTeamMembersInner {
      padding: 10px 12px;

      $gridWidthName: 65%;
      $gridWidthRate: 35%;

      .head {
        display: grid;
        grid-template-columns: $gridWidthName $gridWidthRate;
        font-size: 14px;
        font-weight: $mainFontWeight;
        padding: 0 19px;
      }

      .search {
        margin: 16px 0;

        :global {
          svg {
            top: 7px;
          }

          .UiInput-wrapper {
            margin-bottom: 0;

            .UiInput {
              @include border-radius(16px);

              height: 28px;
            }
          }
        }
      }

      .row {
        display: grid;
        grid-template-columns: 11px calc(#{$gridWidthName} - 11px - 14px - 11px) $gridWidthRate 11px;
        justify-content: left;
        align-items: baseline;
        gap: 6px;

        .deleteEmail {
          cursor: pointer;
        }

        &:last-of-type {
          :global {
            .UiInput-wrapper {
              margin-bottom: 0;
            }
          }
        }

        :global {
          .UiInput-wrapper {
            margin-bottom: 10px;

            &.checkboxWrapper {
              @include customCheckboxSize(11px);
            }
          }
        }
      }
    }
  }

  .addNewUser {
    padding: 18px 22px;
    border-top: 1px solid $borderColor;

    :global {
      h4 {
        font-size: 20px;
        font-weight: $mainFontWeight;
      }

      p {
        font-size: 12px;
        font-weight: $mainFontWeight;
        margin-bottom: 0;
        text-align: left;
      }
    }

    .addNewUserEmail {
      margin-top: 12px;
      display: flex;

      :global {
        .UiInput-wrapper {
          width: 215px;
          margin-right: 6px;
          margin-bottom: 0;
        }

        .button {
          width: 99px;
          font-size: 12px;
          font-weight: $mainFontWeight;
        }
      }
    }
  }

  .inviteSent {
    position: absolute;
    border: 1px solid $green;
    width: 381px;
    height: 35px;
    background-color: #f1fbf2;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 16px;
    color: $green;
    font-size: 14px;
    font-weight: $mainFontWeight;

    @include border-radius($borderRadius);

    :global {
      svg {
        margin-right: 6px;
      }
    }
  }

  .submit {
    justify-self: center;
  }
}

.results {
  background-color: #fff;
  width: 100%;
  margin-top: 45px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.06);
  border: 1px solid $borderColor;
  position: absolute;
  z-index: 99;
  max-height: 200px;
  overflow: hidden;

  .block {
    padding: 15px;
  }

  @include border-radius($borderRadius);

  .entity {
    .items {
      .item {
        font-size: 12px;
        font-weight: 400;
        padding: 6px;
        cursor: pointer;

        &:nth-child(odd) {
          background-color: $table-striped-bg;
        }

        @include nowrap-ellipsis();

        &:hover {
          background-color: #0000001a;
        }
      }
    }
  }
}
